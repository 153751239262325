import React from 'react'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'
import BuildPostElement from "../components/buildPostElement"
import DefaultLayout from '../components/layout'
import SEO from '../components/seo'

import 'katex/dist/katex.min.css'

class BlogPostTemplate extends React.Component {
  render() {
    const { data } = this.props

    const post = data.prismicPost; 
    const postBody=post.data.body
    return (
      <DefaultLayout>
        <SEO title={post.data.title.text} description={post.data.description.text} />
        <div className="clearfix post-content-box">
          <article className="article-page">
            <div className="page-content">
             
              <div className="wrap-content">
                <header className="header-page">
                  <h1 className="page-title">{data.prismicPost.data.title.text}</h1>
                  <div className="page-date">
                    <span> {new Intl.DateTimeFormat('en-US',{ year: 'numeric', month: 'long'}).format(new Date(post.first_publication_date))} 
                </span>
                  </div>
                </header>
                <div className="float-left">
                <GatsbyImage
                      image={
                        post.data.teaser_image.localFile.childImageSharp.gatsbyImageData
                      }
                      className="post-image"
                      key={
                        post.data.teaser_image.localFile.childImageSharp.gatsbyImageData
                      }
                      alt=""
                    />
              </div>

                {postBody.map(arrayPiece =>  (
                   <BuildPostElement key={arrayPiece.id} postElement={arrayPiece}/>
                 ))}
                 

                <div className="page-footer">
                  <div className="page-tag">
                    {post.tags &&
                      post.tags.map((tag) => (
                        <span key={tag}>
                          <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                            #{tag}
                          </Link>
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </DefaultLayout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
query BlogPost($id: String!) {
  prismicPost(uid: {eq: $id}) {
    id
    first_publication_date
    tags
    data {
      body {
        ... on PrismicPostDataBodyText {
          primary {
            post {
              html
            }
          }
          id
          slice_type
        }
        ... on PrismicPostDataBodyImage {
          primary {
            caption {
              html
            }
            image_type
            post_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 200, 
                
                    placeholder: BLURRED)
                 
                }
              }
            }
          }
          id
          slice_type
        }
        ... on PrismicPostDataBodyQuote {
          primary {
            quote {
              html
            }
          }
          id
          slice_type
        }
      }
  
      description {
        html
        text
      }
      teaser_image {
        url

        localFile {
          childImageSharp {
            gatsbyImageData(width: 200, height: 200, layout: FIXED)
            
          }
        }
      }
      title {
        html
        text
      }
    }
    tags
  }
}
`
