import React from 'react'
 import { GatsbyImage } from "gatsby-plugin-image";
 
 
const BuildPostElement = ({ postElement }) => {

          //post is a single element.
          //check the style and return appropritely
          if (postElement.slice_type==="image"){
            return generateImage(postElement);
    
          }else if (postElement.slice_type==="text"){   
            return generatePost(postElement);
              
          } else{
              return generateQuote(postElement);
          }
              
           
       
        }
 
        function generatePost(postPiece){
   
            return(
                
                    <section dangerouslySetInnerHTML={{ __html: postPiece.primary.post.html}} />
                  
            )
        }

        function generateQuote(postPiece){
            return (
                <div className="quote"> 
                <section dangerouslySetInnerHTML={{ __html: postPiece.primary.quote.html}} />
                </div>
            )
        }


        function generateImage(postPiece ){
 
           let imageType=postPiece.primary.image_type;

           if (imageType==="screenshot"){
               //this image is displayed center page
               return (
                   <div className="center">
                   <GatsbyImage
                       image={postPiece.primary.post_image.localFile.childImageSharp.gatsbyImageData}  alt={''}/>
                   </div>
               );
           }else if (imageType==="float_left"){
            return (
                <div className="float-left">
                <GatsbyImage
                    image={postPiece.primary.post_image.localFile.childImageSharp.gatsbyImageData} alt={''}
                    className="post-image" />
                </div>
            );
           }else if (imageType==="float_right"){
            return (
                <div className="float-right">
                <GatsbyImage
                    image={postPiece.primary.post_image.localFile.childImageSharp.gatsbyImageData}  alt={''}
                    className="post-image" />
                </div>
            );
           }
        
          
        }
export default BuildPostElement

   